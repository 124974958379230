import debounce from 'lodash-es/debounce';
import { mapLegacyPropertyTypes, mapLegacyWaterfronts } from '~/utils/community-filters';
import bigNumber from '~/utils/big-number-format';
import { dollarCompact } from '~/utils/dollar-format';
import { getCommunityFilterMutation } from '~/utils/get-community-filter-mutation';
import { inflect } from '~/utils/inflect';
import { filterPropertyTypeMap, financeTextMap, poolTextMap, structureMap, waterfrontMap } from '~/utils/filter-name-map';

const REFRESH_DELAY_MS = 700;

export const frontExposureLabels = {
  'east': 'East',
  'north': 'North',
  'northeast': 'Northeast',
  'northwest': 'Northwest',
  'south': 'South',
  'southeast': 'Southeast',
  'southwest': 'Southwest',
  'west': 'West',
};

export const listingStatusLabels = {
  'active': 'Active',
  'coming-soon': 'Coming Soon',
  'pending': 'Under Contract',
}

export const newConstructionLabels = {
  true: 'All New Construction',
  plan: 'Ready-to-Build',
  spec: 'Move-in Ready',
};

const timeLabels = {
  'today': 'New Today',
  '3d': 'Less than 3 Days',
  '7d': 'Less than 7 Days',
  '14d': 'Less than 14 Days',
  '21d': 'Less than 21 Days',
  '21D': 'More than 21 Days',
};

const tourLabels = {
  'open-houses': 'Open Houses',
  'photos': 'Has Photos',
  'virtual-tours': 'Virtual Tours',
};

const filterToQueryParam = {
  clubFilter: 'club',
  financeFilter: 'finances',
  frontExposureFilter: 'exposure',
  listingStatusFilter: 'listingStatus',
  minAreaFilter: 'minArea',
  maxAreaFilter: 'maxArea',
  minBathFilter: 'minBaths',
  maxBathFilter: 'maxBaths',
  minBedFilter: 'minBeds',
  maxBedFilter: 'maxBeds',
  minGarageFilter: 'minGarages',
  maxGarageFilter: 'maxGarages',
  minPriceFilter: 'minprice',
  maxPriceFilter: 'maxprice',
  minYearFilter: 'minYear',
  maxYearFilter: 'maxYear',
  newConstructionFilter: 'new_construction',
  petFilter: 'pets',
  poolFilter: 'pool',
  propertyTypeFilter: 'propertype',
  structureFilter: 'structure',
  timeFilter: 'time',
  tourFilter: 'tours',
  waterfrontFilter: 'waterfront',
}

export const state = () => ({
  clubFilter: null,
  filterModalOpen: false,
  financeFilter: [],
  financeOptions: new Set(Object.keys(financeTextMap)),
  frontExposureFilter: [],
  frontExposureOptions: new Set(Object.keys(frontExposureLabels)),
  listingStatusFilter: [],
  listingStatusOptions: new Set(Object.keys(listingStatusLabels)),
  minAreaFilter: '',
  maxAreaFilter: '',
  minBathFilter: null,
  maxBathFilter: null,
  minBedFilter: null,
  maxBedFilter: null,
  minGarageFilter: null,
  maxGarageFilter: null,
  minPriceFilter: null,
  maxPriceFilter: null,
  minYearFilter: '',
  maxYearFilter: '',
  newConstructionFilter: [],
  newConstructionOptions: new Set(Object.keys(newConstructionLabels)),
  pills: [],
  petFilter: null,
  poolFilter: [],
  poolOptions: new Set(),
  propertyTypeFilter: [],
  propertyTypeOptions: new Set(),
  shouldHydrate: false,
  structureFilter: [],
  structureOptions: new Set(Object.keys(structureMap)),
  timeFilter: null,
  timeOptions: new Set(Object.keys(timeLabels)),
  tourFilter: [],
  tourOptions: new Set(Object.keys(tourLabels)),
  waterfrontFilter: [],
  waterfrontOptions: new Set(),
});

export const getters = {
  filterCount(state) {
    return state.pills.length;
  },

  hasFilterApplied(state) {
    if (
      state.clubFilter !== null ||
      state.financeFilter.length ||
      state.frontExposureFilter.length ||
      state.listingStatusFilter.length ||
      state.minAreaFilter ||
      state.maxAreaFilter ||
      state.minBathFilter ||
      state.maxBathFilter ||
      state.minBedFilter ||
      state.maxBedFilter ||
      state.minGarageFilter ||
      state.maxGarageFilter ||
      state.minPriceFilter ||
      state.maxPriceFilter ||
      state.minYearFilter ||
      state.maxYearFilter ||
      state.newConstructionFilter.length ||
      state.petFilter !== null ||
      state.poolFilter.length ||
      state.propertyTypeFilter.length ||
      state.structureFilter.length ||
      state.timeFilter ||
      state.tourFilter.length ||
      state.waterfrontFilter.length
    ) {
      return true;
    }
    return false;
  },

  hasPropertyTypeFilter(state) {
    return state.propertyTypeFilter.length > 0;
  },

  hasWaterfrontFilter(state) {
    return state.waterfrontFilter.length > 0;
  },

  propertyTypes(state) {
    return state.propertyTypeFilter;
  },

  waterfronts(state) {
    return state.waterfrontFilter;
  },
};

export const mutations = {
  REFRESH_PILLS(state) {
    const pills = [];

    if (state.listingStatusFilter.length) {
      state.listingStatusFilter.forEach((value) => {
        pills.push({
          // eslint-disable-next-line security/detect-object-injection
          label: listingStatusLabels[value] ?? value,
          mutation: 'REMOVE_FILTER',
          payload: {
            name: 'listingStatusFilter',
            value,
          },
        });
      });
    }

    if (state.minBedFilter) {
      pills.push({
        label: `Min ${state.minBedFilter} ${inflect(state.minBedFilter, 'Bed')}`,
        mutation: 'SET_MIN_BED_FILTER',
      });
    }

    if (state.maxBedFilter) {
      pills.push({
        label: `Max ${state.maxBedFilter} ${inflect(state.maxBedFilter, 'Bed')}`,
        mutation: 'SET_MAX_BED_FILTER',
      });
    }

    if (state.minBathFilter) {
      pills.push({
        label: `Min ${state.minBathFilter} ${inflect(state.minBathFilter, 'Bath')}`,
        mutation: 'SET_MIN_BATH_FILTER',
      });
    }

    if (state.maxBathFilter) {
      pills.push({
        label: `Max ${state.maxBathFilter} ${inflect(state.maxBathFilter, 'Bath')}`,
        mutation: 'SET_MAX_BATH_FILTER',
      });
    }

    if (state.minGarageFilter) {
      pills.push({
        label: `Min ${state.minGarageFilter} ${inflect(state.minGarageFilter, 'Garage')}`,
        mutation: 'SET_MIN_GARAGE_FILTER',
      });
    }

    if (state.maxGarageFilter) {
      pills.push({
        label: `Max ${state.maxGarageFilter} ${inflect(state.maxGarageFilter, 'Garage')}`,
        mutation: 'SET_MAX_GARAGE_FILTER',
      });
    }

    state.newConstructionFilter.forEach((value) => {
      pills.push({
        // eslint-disable-next-line security/detect-object-injection
        label: newConstructionLabels[value] ?? value,
        mutation: 'REMOVE_FILTER',
        payload: {
          name: 'newConstructionFilter',
          value,
        },
      })
    });

    state.propertyTypeFilter.forEach((value) => {
      pills.push({
        // eslint-disable-next-line security/detect-object-injection
        label: filterPropertyTypeMap[value] ?? value,
        mutation: 'REMOVE_FILTER',
        payload: {
          name: 'propertyTypeFilter',
          value,
        },
      });
    });

    state.structureFilter.forEach((value) => {
      pills.push({
        // eslint-disable-next-line security/detect-object-injection
        label: structureMap[value] ?? value,
        mutation: 'REMOVE_FILTER',
        payload: {
          name: 'structureFilter',
          value,
        },
      });
    });

    if (state.minPriceFilter) {
      pills.push({
        label: `Min ${dollarCompact.format(state.minPriceFilter)}`,
        mutation: 'SET_MIN_PRICE_FILTER',
      });
    }

    if (state.maxPriceFilter) {
      pills.push({
        label: `Max ${dollarCompact.format(state.maxPriceFilter)}`,
        mutation: 'SET_MAX_PRICE_FILTER',
      });
    }

    if (state.minYearFilter) {
      pills.push({
        label: `Min Year ${state.minYearFilter}`,
        mutation: 'SET_MIN_YEAR_FILTER',
      })
    }

    if (state.maxYearFilter) {
      pills.push({
        label: `Max Year ${state.maxYearFilter}`,
        mutation: 'SET_MAX_YEAR_FILTER',
      })
    }

    if (state.minAreaFilter) {
      pills.push({
        label: `Min ${bigNumber.format(state.minAreaFilter)} sqft`,
        mutation: 'SET_MIN_AREA_FILTER',
      });
    }

    if (state.maxAreaFilter) {
      pills.push({
        label: `Max ${bigNumber.format(state.maxAreaFilter)} sqft`,
        mutation: 'SET_MAX_AREA_FILTER',
      });
    }

    if (state.timeFilter) {
      pills.push({
        label: timeLabels[state.timeFilter] ?? state.timeFilter,
        mutation: 'SET_TIME_FILTER',
      });
    }

    state.poolFilter.forEach((value) => {
      pills.push({
        // eslint-disable-next-line security/detect-object-injection
        label: poolTextMap[value] ?? value,
        mutation: 'REMOVE_FILTER',
        payload: {
          name: 'poolFilter',
          value,
        },
      });
    });

    state.waterfrontFilter.forEach((value) => {
      pills.push({
        // eslint-disable-next-line security/detect-object-injection
        label: waterfrontMap[value] ?? value,
        mutation: 'REMOVE_FILTER',
        payload: {
          name: 'waterfrontFilter',
          value,
        },
      });
    });

    state.tourFilter.forEach((value) => {
      pills.push({
        // eslint-disable-next-line security/detect-object-injection
        label: tourLabels[value] ?? value,
        mutation: 'REMOVE_FILTER',
        payload: {
          name: 'tourFilter',
          value,
        },
      });
    });

    if (state.petFilter !== null) {
      pills.push({
        label: state.petFilter ? 'Pets Allowed' : 'No Pets Allowed',
        mutation: 'SET_PET_FILTER',
      });
    }

    state.frontExposureFilter.forEach((value) => {
      pills.push({
        // eslint-disable-next-line security/detect-object-injection
        label: frontExposureLabels[value] ?? value,
        mutation: 'REMOVE_FILTER',
        payload: {
          name: 'frontExposureFilter',
          value,
        },
      });
    });

   if (state.clubFilter !== null) {
      pills.push({
        label: state.clubFilter ? 'Club Memberships' : 'No Club Memberships',
        mutation: 'SET_CLUB_FILTER',
      });
    }

    state.financeFilter.forEach((value) => {
      pills.push({
        // eslint-disable-next-line security/detect-object-injection
        label: financeTextMap[value] ?? value,
        mutation: 'REMOVE_FILTER',
        payload: {
          name: 'financeFilter',
          value,
        },
      });
    });

    state.pills = pills;
  },

  REMOVE_FILTER(state, payload) {
    state[payload.name] = state[payload.name].filter((value) => value !== payload.value);
  },

  REMOVE_PILL(state, pill) {
    state.pills = state.pills.filter((p) => p !== pill);
  },

  SET_CLUB_FILTER(state, value) {
    if (typeof value === 'boolean') {
      state.clubFilter = value;
    } else if (value === 'true' || value === 'false') {
      state.clubFilter = value === 'true';
    } else {
      state.clubFilter = null;
    }
  },

  SET_FILTER_MODAL_OPEN(state, value) {
    state.filterModalOpen = value;
  },

  SET_FINANCE_FILTER(state, values) {
    if (Array.isArray(values)) {
      state.financeFilter = values.filter((value) => state.financeOptions.has(value));
    } else {
      state.financeFilter = [];
    }
  },

  SET_FRONT_EXPOSURE_FILTER(state, values) {
    if (Array.isArray(values)) {
      state.frontExposureFilter = values.filter((value) => state.frontExposureOptions.has(value));
    } else {
      state.frontExposureFilter = [];
    }
  },

  SET_LISTING_STATUS_FILTER(state, values) {
    if (Array.isArray(values)) {
      state.listingStatusFilter = values.filter((value) => state.listingStatusOptions.has(value));
    } else {
      state.listingStatusFilter = [];
    }
  },

  SET_MIN_AREA_FILTER(state, value = '') {
    state.minAreaFilter = value;
  },

  SET_MAX_AREA_FILTER(state, value = '') {
    state.maxAreaFilter = value;
  },

  SET_MIN_BATH_FILTER(state, value) {
    state.minBathFilter = Number(value) || null;
  },

  SET_MAX_BATH_FILTER(state, value) {
    state.maxBathFilter = Number(value) || null;
  },

  SET_MIN_BED_FILTER(state, value) {
    state.minBedFilter = Number(value) || null;
  },

  SET_MAX_BED_FILTER(state, value) {
    state.maxBedFilter = Number(value) || null;
  },

  SET_MIN_GARAGE_FILTER(state, value) {
    state.minGarageFilter = Number(value) || null;
  },

  SET_MAX_GARAGE_FILTER(state, value) {
    state.maxGarageFilter = Number(value) || null;
  },

  SET_MIN_PRICE_FILTER(state, value = null) {
    state.minPriceFilter = Number(value) || null;
  },

  SET_MAX_PRICE_FILTER(state, value) {
    state.maxPriceFilter = Number(value) || null;
  },

  SET_MIN_YEAR_FILTER(state, value = '') {
    state.minYearFilter = value;
  },

  SET_MAX_YEAR_FILTER(state, value = '') {
    state.maxYearFilter = value;
  },

  SET_NEW_CONSTRUCTION_FILTER(state, values) {
    if (Array.isArray(values)) {
      state.newConstructionFilter = values.filter((value) => state.newConstructionOptions.has(value));
    } else {
      state.newConstructionFilter = [];
    }
  },

  SET_PET_FILTER(state, value) {
    if (typeof value === 'boolean') {
      state.petFilter = value;
    } else if (value === 'true' || value === 'false') {
      state.petFilter = value === 'true';
    } else {
      state.petFilter = null;
    }
  },

  SET_POOL_FILTER(state, values) {
    if (Array.isArray(values)) {
      state.poolFilter = values.filter((v) => state.poolOptions.has(v));
    } else {
      state.poolFilter = [];
    }
  },

  SET_POOL_OPTIONS(state, values) {
    state.poolOptions = new Set(values);
  },

  SET_PROPERTY_TYPE_FILTER(state, values) {
    if (Array.isArray(values)) {
      state.propertyTypeFilter = mapLegacyPropertyTypes(values).filter((value) => state.propertyTypeOptions.has(value));
    } else {
      state.propertyTypeFilter = [];
    }
  },

  SET_PROPERTY_TYPE_OPTIONS(state, values) {
    state.propertyTypeOptions = new Set(values);
  },

  SET_STRUCTURE_FILTER(state, values) {
    if (Array.isArray(values)) {
      state.structureFilter = values.filter((v) => state.structureOptions.has(v));
    } else {
      state.structureFilter = [];
    }
  },

  SET_TIME_FILTER(state, value) {
    if (state.timeOptions.has(value)) {
      state.timeFilter = value;
    } else {
      state.timeFilter = null;
    }
  },

  SET_TOUR_FILTER(state, values) {
    if (Array.isArray(values)) {
      state.tourFilter = values.filter((v) => state.tourOptions.has(v));
    } else {
      state.tourFilter = [];
    }
  },

  SET_WATERFRONT_FILTER(state, values) {
    if (Array.isArray(values)) {
      state.waterfrontFilter = mapLegacyWaterfronts(values).filter((v) => state.waterfrontOptions.has(v));
    } else {
      state.waterfrontFilter = [];
    }
  },

  SET_WATERFRONT_OPTIONS(state, values) {
    state.waterfrontOptions = new Set(values);
  },

  TRIGGER_HYDRATION(state) {
    state.shouldHydrate = true;
  },
};

export const actions = {
  async applyFilterQuery({ commit, dispatch }, router) {
    commit('TRIGGER_HYDRATION');

    const { path } = router.currentRoute.value;
    const query = await dispatch('getFilterQuery', router);
    router.push({ path, query });

    commit('REFRESH_PILLS');
  },

  clearFilters({ commit }) {
    Object.keys(filterToQueryParam).forEach((filter) => {
      commit(getCommunityFilterMutation(filter));
    });
  },

  getFilterQuery({ state }, router) {
    const query = { ...router.currentRoute.value.query };

    Object.entries(filterToQueryParam).forEach(([filter, param]) => {
      /* eslint-disable security/detect-object-injection */
      const value = state[filter];

      if (Array.isArray(value)) {
        if (value.length) {
          query[param] = value.join(',');
        } else {
          delete query[param];
        }
      } else if (typeof value === 'boolean') {
        query[param] = value;
      } else if (value) {
        query[param] = value;
      } else {
        delete query[param];
      }
      /* eslint-enable security/detect-object-injection */
    });

    return query;
  },

  initializeFilters({ commit, state }, { options, route }) {
    commit('SET_PROPERTY_TYPE_OPTIONS', options.propertyTypes);
    commit('SET_POOL_OPTIONS', options.poolTypes);
    commit('SET_WATERFRONT_OPTIONS', options.waterfrontTypes);

    Object.entries(filterToQueryParam).forEach(([filter, param]) => {
      /* eslint-disable security/detect-object-injection */
      const value = route.query[param];
      if (value) {
        const mutation = getCommunityFilterMutation(filter);
        if (Array.isArray(state[filter])) {
          commit(mutation, value.split(','));
        } else {
          commit(mutation, value);
        }
      }
      /* eslint-disable security/detect-object-injection */
    });

    commit('REFRESH_PILLS');
  },

  refreshRouteQuery: debounce(({ dispatch }, router) => {
    dispatch('applyFilterQuery', router);
  }, REFRESH_DELAY_MS),

  removePill({ commit }, pill) {
    commit('REMOVE_PILL', pill);
    commit(pill.mutation, pill.payload);
  },

  setFilterModalOpen({ commit }, value) {
    commit('SET_FILTER_MODAL_OPEN', value);
  },

  triggerHydration({ commit }) {
    commit('TRIGGER_HYDRATION');
  },
};

export const namespaced = true;
