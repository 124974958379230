export const legacyPropertyTypes = {
  Condo: 'condominium',
  Townhouse: 'townhouse',
};

export const legacyWaterfronts = {
  Deepwater: 'deep-water',
  Oceanfront: 'ocean-front',
  Yes: 'other',
};

function mapParam(string, mapFn) {
  return mapFn(string.split(',')).join(',');
}

export function mapLegacyPropertyTypes(values) {
  return values.map((value) => legacyPropertyTypes[value] ?? value);
}

export function mapLegacyWaterfronts(values) {
  return values.map((value) => legacyWaterfronts[value] ?? value);
}

/**
 * Converts route query params to the key/value pairs needed for API calls.
 *
 * This is used to maintain backwards compatibility with legacy frontend query
 * string params. Apparently search rankings rely heavily on these old values
 * and changing them triggers the apocalypse.
 *
 * The following alterations will be made:
 *
 * - `propertype` will be renamed `propertyType` and map its legacy values
 * - `minprice` will be renamed `minPrice`
 * - `maxprice` will be renamed `maxPrice`
 * - `waterfront` will map its legacy values
 *
 * @param {object} query route query params to convert
 * @returns {object} the converted params
 */
export function getApiQueryParams(route) {
  const query = { ...route.query };

  if (query.propertype) {
    query.propertyType = mapParam(query.propertype, mapLegacyPropertyTypes);
    delete query.propertype;
  }

  if (query.minprice) {
    query.minPrice = query.minprice;
    delete query.minprice;
  }

  if (query.maxprice) {
    query.maxPrice = query.maxprice;
    delete query.maxprice;
  }

  if (query.waterfront) {
    query.waterfront = mapParam(query.waterfront, mapLegacyWaterfronts);
  }

  return query;
}
