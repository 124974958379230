/**
  * An enum of action types associated with an event
 * @enum {String}
 */
export const actionTypes = {
  /** User has closed or dismissed an item we wish to track */
  closed: 'closed',
  /** User has clicked on an item we wish to track */
  clicked: 'clicked',
  /** The action resulted in failure */
  failed: 'failed',
  /** The action was successful */
  success: 'success',
  /** User viewed an item we wish to track */
  viewed: 'viewed',
};

/**
 * An enum of auth methods to send with recommended login and sign up events
 * @enum {String}
 */
export const authMethods = {
  appleButton: 'apple-button',
  bex: 'bex',
  facebookButton: 'facebook-button',
  googleButton: 'google-button',
  googleOneTap: 'google-one-tap',
}

/**
 * Categorization of types of pages we have on our website. These constants can be accessed in
 * `feed_target.bexrealtycom_page_type`. We add the 'other' route as a placeholder for any page
 * types not specifically defined
 * @enum {String}
 */
export const bexrealtyComPageTypes = {
  community: 'community',
  communityRental: 'community-rental',
  comp: 'comp',
  home: 'home',
  htmlSitemap: 'html-sitemap',
  htmlSitemapRental: 'html-sitemap-rental',
  listing: 'listing',
  listingRental: 'listing-rental',
  other: 'other',
  resource: 'resource',
  state: 'state',
  stateRental: 'state-rental',
  zip: 'zip',
  zipRental: 'zip-rental',
};

/**
 * An enum of CTA ids
 * @enum {String}
 */
export const ctaIds = {
  /**
   * Alerts CTAs
   */
  /** Tracks when a user clicks "Save Search" on the listing page top menu bar. Does not save search, only shows form */
  alertsListingPage: 'alerts-listing-page',
  /** Tracks when a user saves a search on the communities in component */
  saveSearchBtnCommunitiesIn: 'save-search-button-communities-in',
  /** Tracks when a user saves a search via the “Save Search” button on community pages */
  saveSearchBtnCommunityPage: 'save-search-button-community-page',
  /** Tracks when a user saves a search via the listing page save search form */
  saveSearchBtnListingPage: 'save-search-button-listing-page',

  /**
   * App Store Buttons and CTAs
   */
  /** Tracks when a user clicks the banner shown on mobile web devices sized 768px wide and smaller */
  appButtonMobileBanner: 'app-button-mobile-banner',
  /** Tracks when a user clicks Apple App button in footer */
  appleAppButtonFooter: 'apple-app-button-footer',
  /** Tracks when a user clicks Apple App button in the hamburger nav in the header */
  appleAppButtonHeader: 'apple-app-button-header',
  /** Tracks when a user clicks Apple App button on the homepage */
  appleAppButtonHomePage: 'apple-app-button-home-page',
  /** Tracks when a user clicks Apple App button on the app landing page */
  appleAppButtonAppLandingPage: 'apple-app-button-app-landing-page',
  /** Tracks when a user clicks Google Play App button in footer */
  googleAppButtonFooter: 'google-app-button-footer',
  /** Tracks when a user clicks Google Play App button in the hamburger nav in the header */
  googleAppButtonHeader: 'google-app-button-header',
  /** Tracks when a user clicks Google Play App button on the homepage */
  googleAppButtonHomePage: 'google-app-button-home-page',
  /** Tracks when a user clicks Google Play App button on the app landing page */
  googleAppButtonAppLandingPage: 'google-app-button-app-landing-page',


  /**
   * Auth CTAs
   */
  /** Tracks BEX login actions */
  bexLogin: 'bex-login',
  /** Tracks BEX login Apple Auth actions */
  bexLoginAppleAuth: 'bex-login-apple-auth',
  /** Tracks complete registration form that is shown after logging in*/
  bexLoginCompleteRegistration: 'bex-login-complete-registration',
  /** Tracks BEX login Facebook Auth actions */
  bexLoginFacebookAuth: 'bex-login-facebook-auth',
  /** Tracks BEX login Google Auth actions */
  bexLoginGoogleAuth: 'bex-login-google-auth',
  /** Tracks invalid phone popup form that is shown after logging in */
  bexLoginInvalidPhonePopup: 'bex-login-invalid-phone-popup',
  /** Tracks BEX sign up actions */
  bexSignUp: 'bex-sign-up',
  /** Tracks BEX sign up Facebook Auth actions */
  bexSignUpFacebookAuth: 'bex-sign-up-facebook-auth',
  /** Tracks BEX sign up Google Auth actions */
  bexSignUpGoogleAuth: 'bex-sign-up-google-auth',

  /**
   * Inquiry/Contact CTAs
   * Inquiry CTA ids may be augmented if the CTA was on a secondary inquiry form.
   * This happens by appending `-secondary` to the cta id.
   * e.g. If on the ask a local expert secondary inquiry form, the cta id will be
   * `inquiry-listing-page-ask-local-expert-secondary`.
   */
  /** Tracks the "Do Not Sell or Share My Personal Information" form on CCPA form page */
  ccpaForm: 'ccpa-form',
  /** Tracks the "inquiry form on the agent page" */
  inquiryAgentPageContactForm: 'inquiry-agent-page-generic-contact-form',
  /** Tracks the inquiry form on the community page */
  inquiryCommunityPageContactAgent: 'inquiry-community-page-contact-agent',
    /** Tracks the inquiry form on the community page - in image lightbox */
  inquiryCommunityPageContactAgentLightbox: 'inquiry-community-page-contact-agent-lightbox',
  /** Tracks the inquiry form on the contact.php page */
  inquiryContactPageContactForm: 'inquiry-contact-page-contact-form',
  /** Tracks the mortgage inquiry form on the community page from "Get a Mortgage" button */
  inquiryCommunityPageMortgageButton: 'inquiry-community-page-mortgage-button',
  /** Tracks the listing page inquiry form titled Ask Local Expert */
  inquiryListingPageAskLocalExpert: 'inquiry-listing-page-ask-local-expert',
  /** Tracks the listing page inquiry form for claiming home */
  inquiryListingPageClaimHome: 'inquiry-listing-page-claim-home',
  /** Tracks the listing page inquiry form for Contact Our Agent button */
  inquiryListingPageContactAgent: 'inquiry-listing-page-contact-agent',
  /** Tracks the listing page inquiry form for when a user has entered through an alert email "Contact Agent" button */
  inquiryListingPageContactAgentAlertCta: 'inquiry-listing-page-contact-agent-alert-cta',
  /** Tracks the listing page inquiry form for Contact Our Agent button for mobile only */
  inquiryListingPageContactAgentMobilePopup: 'inquiry-listing-page-contact-agent-mobile-popup',
  /** Tracks the listing page inquiry form for Contact Our Agent button when there is no listing price */
  inquiryListingPageContactAgentNoPrice: 'inquiry-listing-page-contact-agent-no-price',
  /** Tracks the listing page inquiry form in the lightbox */
  inquiryListingPageLightboxAskLocalExpert: 'inquiry-listing-page-lightbox-ask-local-expert',
  /** Tracks the listing page mortgage form in the lightbox */
  inquiryListingPageLightboxMortgage: 'inquiry-listing-page-lightbox-mortgage',
  /** Tracks the listing page inquiry form for schedule a private showing */
  inquiryListingPagePrivateShowing: 'inquiry-listing-page-private-showing',
  /** Tracks the mobile view listing page sticky button for schedule a private showing */
  inquiryListingPagePrivateShowingMobile: 'inquiry-listing-page-private-showing-mobile',
  /** Tracks the inquiry form for mobile search */
  inquiryMobileSearchContactAgent: 'inquiry-mobile-search-contact-agent',
  /** Tracks the listing page inquiry form in the set alerts form confirmation view */
  inquiryListingPageSetAlerts: 'inquiry-listing-page-set-alerts-confirmation',
  /** Tracks the inquiry form in the mortgage calculator footer */
  inquiryMortgageCalcFooter: 'inquiry-mortgage-calculator-footer',
  /** Tracks the inquiry form on the sell page */
  inquirySellPageContactForm: 'inquiry-sell-page-contact-form',
  /** Tracks the lead program digital advertising form */
  leadProgramDigitalAdvertisingForm: 'lead-program-digital-advertising-form',
  /** Tracks the leads isa form. On /agents/lead-programs/interests?step-=real-estate-nurture */
  leadProgramLeadsIsaForm: 'lead-program-leads-isa-form',
  /** Tracks the lead program marketing landing form in the footer of /agents/lead-programs/ “Send a Message” CTA */
  leadProgramMarketingLandingFooterForm: 'lead-program-marketing-landing-footer-form',
  /** Tracks the lead program marking landing form on /leads-isa/ page */
  leadProgramMarketingLandingForm: 'lead-program-marketing-landing-form',
  /** Tracks the contact form for requesting a deletion of PII */
  privacyPolicyPagePersonalInfoForm: 'privacy-policy-personal-info-form',

  /**
   * Listing Office CTAs
   */
  /** Tracks when a user tries to click office contact email */
  listingOfficeContactEmail: 'listing-office-contact-email',
  /** Tracks when a user tries to click office contact phone */
  listingOfficeContactPhone: 'listing-office-contact-phone',

  /**
   * MISC CTAs
   */
  /** Tracks when the favorite listing button is clicked on a listing detail page */
  listingPageFavorite: 'listing-page-favorite',
  /** Tracks when the lightbox is clicked on a listing detail page */
  listingPageLightbox: 'listing-page-lightbox',
  /** Tracks when the share listing button is clicked ona listing detail page */
  listingPageShare: 'listing-page-share',
  /** Tracks when the map modal is opened on a listing detail page */
  listingPageShowMap: 'listing-page-show-map',

  /**
   * Mortgage Calculator CTAs
   */
  /**
   * Tracks mortgage calculator actions. This property is used as a template to build other ids.
   * e.g. accortion-header appends accordion type. Example: `mortgage-calculator-utilities-header`
   */
  mortgageCalculator: 'mortgage-calculator',
  rentalCalculator: 'rental-calculator',

  /**
   * Third Party Widget CTAs: These CTAs open third party widget integrations
   */
  /** Tracks the CTA for auto insurance widget in the mortgage calculator */
  autoInsuranceGetQuote: 'mortgage-calculator-auto-insurance-get-quote',

  /** Tracks the CTA for bankrate auto insurance widget in the mortgage calculator */
  bankrateGetAutoQuote: 'mortgage-calculator-bankrate-get-auto-quote',
  /** Tracks the CTA for bankrate auto insurance widget in the mortgage calculator */
  bankrateGetAutoQuoteRental: 'rental-calculator-bankrate-get-auto-quote',

  /** Tracks the Ad for Cordless "Your credit can affect your interest rate" link in the mortgage calculator */
  cordlessCreditScore: 'cordless-credit-score-mortgage-calculator',
  /** Tracks the CTA for Cordless credit rate button in the secondary inquiry form */
  cordlessCreditScoreSecondaryForm: 'secondary-inquiry-cordless-credit-score',
  /** Tracks the CTA for “Compare Rates for Internet Service” button for the cordless widget in the secondary inquiry form */
  cordlessInternetQuoteSecondaryForm: 'secondary-inquiry-cordless-internet-quote',
  /** Tracks the CTA for Cordless internet provider widget in the mortgage calculator */
  cordlessInternetQuoteMortgageCalc: 'mortgage-calculator-cordless-internet-quote',
  /** Tracks the CTA for Cordless internet provider widget in the property features section of listing pages */
  cordlessInternetQuotePropertyFeatures: 'property-features-cordless-internet-quote',
  /** Tracks the CTA for Cordless internet provider widget in the rental calculator */
  cordlessInternetQuoteRentalCalc: 'rental-calculator-cordless-internet-quote',
  /** Tracks the CTA for Cordless Mortgage under the listing price */
  cordlessMortgageUnderPrice: 'listing-page-cordless-under-price',
  /** Tracks the CTA for Cordless Mortgage under the listing price on rentals */
  cordlessMortgageUnderPriceRental: 'listing-page-cordless-under-price-rental',
  /** Tracks the CTA for “Get Pre-Approved” button in the mortgage calculator */
  cordlessMortgageQuoteMortgageCalc: 'mortgage-calculator-cordless-mortgage-quote',
  /** Tracks the CTA for Cordless Mortgage button below the mortgage calculator */
  cordlessMortgageBelowMortgageCalc: 'listing-page-cordless-below-calc-pre-approved',
  /** Tracks the CTA for Cordless Mortgage button in the rental calculator */
  cordlessMortgageQuoteRentalCalc: 'rental-calculator-cordless-mortgage-quote',

  /** Tracks the CTA for mediaAlpha homeowners insurance widget in the secondary inquiry forms */
  mediaAlphaHomeInsuranceForm: 'secondary-inquiry-media-alpha-home-insurance-quote',
  /** Tracks the CTA for mediaAlpha homeowners insurance widget in the mortgage calculator */
  mediaAlphaHomeInsuranceGetQuote: 'mortgage-calculator-media-alpha-get-home-quote',
  /** Tracks the CTA for mediaAlpha renters insurance widget in the secondary inquiry forms */
  mediaAlphaRentersInsuranceForm: 'secondary-inquiry-media-alpha-renters-insurance-quote',
  /** Tracks the CTA for mediaAlpha renters insurance widget under price on renal listing pages */
  mediaAlphaRentersInsuranceUnderPrice: 'listing-page-media-alpha-renters-insurance-under-price',
  /** Tracks the CTA for mediaAlpha renters insurance widget in the rental calculator */
  mediaAlphaRentersInsuranceGetQuote: 'rental-calculator-media-alpha-get-renters-quote',

  /** Tracks the CTA for VA Loans below the donut on mortgage calculator */
  vaLoansButton: 'mortgage-calculator-va-loans-below-donut',
  /** Tracks the CTA for VA Loans link in the global footer nav */
  vaLoansFooterNav: 'va-loans-footer-nav',
  /** Tracks the CTA for VA Loans link in the global nav bar */
  vaLoansNavBar: 'va-loans-nav-bar',
  /** Tracks the CTA for VA Loans widget under the listing price. */
  vaLoansUnderPrice: 'va-loans-under-price',
  /** Tracks the CTA for VA Loans widget under the rental listing price. */
  vaLoansUnderPriceRental: 'va-loans-under-price-rental',

  /** Tracks the CTA for MRC ICB rate table under the listing price. */
  mrcRateTableUnderPrice: 'listing-page-icb-under-price',
  /** Tracks the CTA for MRC ICB rate table above the mortgage calculator APR input */
  mrcRateTableMortCalcApr: 'mortgage-calculator-icb-apr-input',
  /** Tracks the CTA for MRC ICB rate table above the mortgage calculator donut */
  mrcRateTableAboveDonut: 'mortgage-calculator-icb-above-donut',
  /** Tracks the CTA for MRC ICB rate table below the mortgage calculator loan section */
  mrcRateTableBelowLoanTerms: 'mortgage-calculator-icb-below-loan-section',
  /** Tracks the CTA for MRC ICB rate table below the rental calculator donut */
  mrcRateTableBelowDonutRental: 'rental-calculator-icb-below-donut',
  /** Tracks the CTA for MRC widget on listing pages. Also used as MRC campaign name */
  mrcConnectLenderSection: 'listing-page-mrc-connect-lender-section',
  /** Tracks the CTA for MRC pre approved link in the top nav bar */
  mrcNavBarPreApproved: 'nav-bar-mrc-pre-approved',
  /** Tracks the CTA for MRC equity loans link in the top nav bar */
  mrcNavBarEquityLoans: 'nav-bar-mrc-equity-loans',
  /** Tracks the CTA for MRC mortgage rate table page in the top nav bar */
  mrcNavBarMortgageRates: 'nav-bar-mrc-mortgage-rates',
  /** Tracks the CTA for MRC current rates link in the footer */
  mrcFooterCurrentRates: 'footer-mrc-current-rates',
  /** Tracks the CTA for MRC pre approved link in the footer */
  mrcFooterPreApproved: 'footer-mrc-pre-approved',
  /** Tracks the CTA for MRC equity loans link in the footer */
  mrcFooterEquityLoans: 'footer-mrc-equity-loans',

  /** Tracks the CTA for Offerpad Cash Offer btn below the down payment field in the mortgage calculator */
  offerpadCashOfferBelowDownPaymentMortgageCalc: 'mortgage-calculator-offerpad-cash-offer-below-down-payment',
  /** Tracks the CTA for "Cash Offer" in the secondary inquiry forms  */
  offerpadCashOfferSecondaryForm: 'secondary-inquiry-offerpad-cash-offer-btn',
  /** Tracks the CTA for Offerpad iBuyer on /sell page. */
  offerpadSellPage: 'sell-page-offerpad-cash-offer-btn',
  /** Tracks the CTA for Offerpad under the price on sold listings only (does not include "rented") */
  offerpadUnderPrice: 'listing-page-offerpad-cash-offer-under-price',
}

/**
 * An enum of custom Ad ids. Custom ads are generally a CTA with a link/button that opens a new
 * page. Custom ad ids do not come from the database as the ad manager admin does not support
 * configuration of these ads.
 * @enum {String}
*/
export const customAdIds = {
  /** Tracks the Ad for Accredited Debt Relief link in the mortgage calculator */
  accreditedDebtRelief: 'accredited-debt-relief-mortgage-calculator',
  /** Tracks the CTA for NSure homeowners insurance link in FL only in the mortgage calculator */
  nsureFlorida: 'nsure-florida-mortgage-calculator',
  /** Tracks the Ad for Vivint "Special Offer" link in the mortgage calculator */
  vivint: 'vivint-smart-home-mortgage-calculator',
}

/**
 * An enum of analytics event names
 * @enum {String}
 */
export const eventNames = {
  // GA4 recommended events
  login: 'login',
  search: 'search',
  signUp: 'sign_up',

  // custom events
  /** Call to Action (CTA) type events  */
  cta: 'bex_cta',
  /** Bex Direct Ad events */
  directAd: 'bex_direct_ad',
}
