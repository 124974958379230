import snakeCase from 'lodash-es/snakeCase';
import type { state } from '~/store/community-filters';

type PickFilters<T extends object> = {
  [K in keyof T as K extends `${string}Filter` ? K : never]: T[K];
}

export type CommunityFilter = keyof PickFilters<ReturnType<typeof state>>;

export function getCommunityFilterMutation(filter: CommunityFilter) {
  return `SET_${snakeCase(filter).toUpperCase()}`;
}
